<template>
    <Head>
        <Title>{{ title }}</Title>
        <Meta name="description" :content="description" />
    </Head>

    <NuxtLayout />
</template>

<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();

const title = computed(() =>
    t('layouts.title', { title: t(route.meta.title ?? 'TBD') }),
);
const description = computed(() =>
    route.meta.description
        ? t(route.meta.description)
        : t('layouts.description'),
);
</script>
