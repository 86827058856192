export default defineNuxtPlugin((nuxtApp) => {
    const navigationStore = useNavigationStore();

    useRouter().beforeEach(() => {
        navigationStore.closeNavigation();
    });

    // Provides
    const scrollToTop = (immediate: boolean = false) => {
        const { lenis } = useLenis() as any;
        lenis.value?.scrollTo(0, {
            immediate,
        });
    };
    // END > Provides

    return {
        provide: {
            scrollToTop,
        },
    };
});
