<template>
    <div class="w-screen h-screen text-primary bg-primary">
        <div class="container relative flex flex-col justify-center h-full">
            <div class="absolute w-16 h-16 top-16">
                <nuxt-link
                    class="transition-colors cursor-pointer pointer-events-auto h-14 w-14 hover:text-gray-400"
                    :to="localePath('/')"
                >
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 68 68"
                        style="enable-background: new 0 0 68 68"
                        xml:space="preserve"
                    >
                        <g class="fill-current">
                            <g>
                                <path
                                    d="M0.1,5.5h15.3c10,0,14.4,5.9,14.4,12.2c0,5.7-4.1,11.3-12.6,12.2l14.7,18.7h-7.4L9.8,30H7.5v18.5H0.1V5.5z M7.5,7.3v20.9h5.8c5.2,0,9-5.3,9-11c0-5.7-3.9-9.9-9.2-9.9H7.5z"
                                />
                                <path
                                    d="M49.9,5.5c4.9,0,14,2.2,14,10.9c0,5.1-2.9,8.1-6.7,9.5C62,26.7,68,29.9,68,36.8c0,8.2-7.7,11.8-16.1,11.8 H33.6V5.5H49.9z M41,7.1v18.5h6c4,0,9.5-2,9.5-9.7c0-7.8-5.5-8.8-9.5-8.8H41z M41,46.9h9.6c6.7,0,10-4.7,10-10.3 c0-5.3-3-9.2-9.4-9.2H41V46.9z"
                                />
                            </g>
                            <rect
                                id="Rechteck_7"
                                y="57.2"
                                class="st1"
                                width="68"
                                height="5.4"
                            />
                        </g>
                    </svg>
                </nuxt-link>
            </div>
            <NuxtLink
                :to="localePath('/')"
                class="flex items-center mb-6 text-xl transition-colors gap-x-2 hover:text-gray-300"
            >
                <Icon name="heroicons:arrow-left" />
                {{ t('error.backHome') }}
            </NuxtLink>
            <h1 class="leading-[4.25rem] sm:leading-none text-7xl sm:text-8xl">
                {{ errorMessage }}
            </h1>
            <h2 v-if="sublineMessage" class="mt-8 text-xl">
                {{ sublineMessage }}
            </h2>
            <pre v-if="isDev">{{ error }}</pre>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

const { t, te } = useI18n();
const localePath = useLocalePath();

const props = defineProps({
    error: Object as () => NuxtError,
});

// Computed
const errorMessage = computed(() => {
    if ([404, 500].includes(props.error?.statusCode || 0)) {
        return t(`error.${props.error?.statusCode}.message`);
    }

    return t('error.500');
});
const sublineMessage = computed(() => {
    const messageKey = `error.${props.error?.statusCode}.subline`;

    if ([404, 500].includes(props.error?.statusCode || 0) && te(messageKey)) {
        return t(messageKey);
    }
    return '';
});
const isDev = computed(() => {
    return import.meta.dev;
});
</script>
