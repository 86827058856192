import SplitType from 'split-type';

const initAnimations = () => {
    const gsap = useGsap;

    // Data speed
    gsap.utils
        .toArray('[data-speed]')
        .filter((el) => el instanceof HTMLElement)
        .forEach((el) => {
            gsap.to(el, {
                y: (i, el) => {
                    const dataSpeed =
                        1 - parseFloat(el.getAttribute('data-speed'));
                    return dataSpeed * 300;
                },
                scrollTrigger: {
                    start: 'clamp(top bottom)',
                    end: 'bottom top',
                    scrub: 0,
                    trigger: el,
                },
            });
        });

    // Data Animation
    const dataAnimationElements = gsap.utils
        .toArray('[data-animation]')
        .filter((el) => el instanceof HTMLElement);

    // Data Animation - FADE_UP
    dataAnimationElements
        .filter((el) => el.dataset.animation === 'FADE_UP')
        .forEach((el) => {
            // Remove opacity-0 class from element
            // Needed to not show element from SSR
            el.classList.remove('opacity-0');

            gsap.fromTo(
                el,
                {
                    y: 60,
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 2,
                    delay: (i, element) => {
                        return element.getAttribute('data-animation-delay');
                    },
                    scrollTrigger: {
                        start: () =>
                            el.getAttribute('data-animation-start') ||
                            '100 bottom',
                        end: 'bottom top',
                        trigger: el,
                        scrub: false,
                    },
                },
            );
        });

    // Data Animation - FADE_UP_TEXT
    dataAnimationElements
        .filter((el) => el.dataset.animation === 'FADE_UP_TEXT')
        .forEach((el) => {
            animateTextFadeUp(el, { blur: false, rotate: false, skew: false });
        });

    // Data Animation - FADE_UP_TEXT_BLUR
    dataAnimationElements
        .filter((el) => el.dataset.animation === 'FADE_UP_TEXT_BLUR')
        .forEach((el) => {
            animateTextFadeUp(el, { blur: true, rotate: true, skew: true });
        });
};

// Provides
const staggerFadeUpAnimations = (
    elements: Array<HTMLElement>,
    options?: {
        triggerElement?: HTMLElement;
        scrollTriggerOptions?: ScrollTrigger.Vars;
        stagger?: number;
    },
) => {
    const structuredElements = elements.map((el: any) => {
        if (el instanceof HTMLElement) {
            return el;
        } else if (Object.hasOwn(el, '$el')) {
            return el.$el;
        }
        return null;
    });

    const scrollTriggerFinalOptions = Object.assign(
        {
            start: '100 bottom',
            end: 'bottom top',
            trigger: options?.triggerElement || elements[0],
            scrub: false,
        },
        options?.scrollTriggerOptions,
    );

    useGsap.fromTo(
        structuredElements,
        {
            y: 60,
            opacity: 0,
        },
        {
            y: 0,
            stagger: options?.stagger || 0.4,
            opacity: 1,
            duration: 2,
            scrollTrigger: scrollTriggerFinalOptions,
        },
    );
};

const animateTextFadeUp = (
    textElement: HTMLElement,
    options?: { blur?: boolean; skew?: boolean; rotate?: boolean },
) => {
    const textCharElements = new SplitType(textElement, {
        types: 'lines,words',
        wordClass: 'no-ligature',
        lineClass: 'overflow-hidden pt-8 -mt-8',
    });

    // Remove opacity-0 class from element
    // Needed to not show text from SSR
    textElement.classList.remove('opacity-0');

    useGsap.from(textCharElements.words, {
        opacity: 0,
        y: '100%',
        x: options?.blur || options?.rotate ? 10 : undefined,
        rotate: options?.rotate ? 5 : undefined,
        filter: options?.blur ? 'blur(8px)' : undefined,
        skewX: options?.skew ? '-15deg' : undefined,
        ease: 'power1.out',
        duration: 1,
        stagger: { amount: 0.75 },
        scrollTrigger: {
            start: '100 bottom',
            end: 'bottom top',
            trigger: textElement,
            scrub: false,
        },
    });
};

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hooks.hook('page:loading:end', () => {
        setTimeout(() => {
            initAnimations();
        }, 50);
    });

    return {
        provide: {
            staggerFadeUpAnimations,
            animateTextFadeUp,
        },
    };
});
