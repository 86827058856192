import revive_payload_client_ZnP0qpDBH1 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ILLaOyGThY from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MAQyP8iNRL from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_A2lIG1FZZR from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.2_sass@1.77_6vvhu72z2fdjmhljb6trox7zai/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import debug_S20l0wCGYe from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_4fYiaeygN7 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5xf4gMXGDK from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4tDMF99plb from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0mBwDArvoG from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_nQsR7njl4a from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Cj3zdHtUN2 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_mKyFQbYwf5 from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.2_ryacemkrzoq6jvfqx7wv2cnuvy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_Wi3Np1qkFW from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.2_ryacemkrzoq6jvfqx7wv2cnuvy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_Hlv8OwVaze from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.2_sass@1_o26qn3myah3smjqtjykdp5p5ue/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_hT98ii5u5q from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.2_sass@1_o26qn3myah3smjqtjykdp5p5ue/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_yyzJAYW5Tv from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_1tsy7HIxIh from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.9_magicast@0.3.4_rollup@4.18.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_cYln33KMew from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.2_sass@1.77.6_terser@5.31.1__vue@3.4.31/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_dBLwxDLlTU from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/app/.nuxt/gsapPlugin.mjs";
import plugin_JlV7iqDDKJ from "/app/node_modules/.pnpm/nuxt-lenis@1.2.4_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-lenis/dist/runtime/plugin.mjs";
import i18n_9y9a4NLg1d from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import breakpoints_RLsn2gcpPk from "/app/node_modules/.pnpm/@raphaelbernhart+nuxt-base-layer@0.1.6_h3@1.12.0_magicast@0.3.4_nuxt@3.12.3_@parcel+watcher@2_gft7iotcep4sybbbse4ysk5xqy/node_modules/@raphaelbernhart/nuxt-base-layer/plugins/breakpoints.ts";
import animation_client_L7ARgJ3Fon from "/app/plugins/animation.client.ts";
import navigation_QptjALbk5P from "/app/plugins/navigation.ts";
import plugin_auto_pageviews_client_kHOUA8aGT9 from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
import plugin_auto_outbound_tracking_client_VMlupju2yf from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-outbound-tracking.client.js";
export default [
  revive_payload_client_ZnP0qpDBH1,
  unhead_ILLaOyGThY,
  router_MAQyP8iNRL,
  _0_siteConfig_A2lIG1FZZR,
  debug_S20l0wCGYe,
  payload_client_4fYiaeygN7,
  navigation_repaint_client_5xf4gMXGDK,
  check_outdated_build_client_4tDMF99plb,
  chunk_reload_client_0mBwDArvoG,
  plugin_vue3_nQsR7njl4a,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Cj3zdHtUN2,
  titles_mKyFQbYwf5,
  defaultsWaitI18n_Wi3Np1qkFW,
  siteConfig_Hlv8OwVaze,
  inferSeoMetaPlugin_hT98ii5u5q,
  plugin_client_yyzJAYW5Tv,
  plugin_1tsy7HIxIh,
  plugin_cYln33KMew,
  plugin_dBLwxDLlTU,
  gsapPlugin_HlwIIyl85L,
  plugin_JlV7iqDDKJ,
  i18n_9y9a4NLg1d,
  breakpoints_RLsn2gcpPk,
  animation_client_L7ARgJ3Fon,
  navigation_QptjALbk5P,
  plugin_auto_pageviews_client_kHOUA8aGT9,
  plugin_auto_outbound_tracking_client_VMlupju2yf
]