import { default as agbHWpLh0TOPEMeta } from "/app/pages/agb.vue?macro=true";
import { default as _91id_937E5e9RRlObMeta } from "/app/pages/applications/[id].vue?macro=true";
import { default as imprintXQz1X9eYwAMeta } from "/app/pages/imprint.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as _91id_93UX72SEYcNlMeta } from "/app/pages/works/[id].vue?macro=true";
import { default as index9ZE7rZBSGbMeta } from "/app/pages/works/index.vue?macro=true";
import { default as component_45stuby5E5qKHNZHMeta } from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stuby5E5qKHNZH } from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_ioredis@5.4.1_magicast@_2o5d7tlbasw7ugjgji6rzmiza4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "agb___de",
    path: "/agb",
    component: () => import("/app/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: "agb___en",
    path: "/en/agb",
    component: () => import("/app/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: "applications-id___de",
    path: "/applications/:id()",
    meta: _91id_937E5e9RRlObMeta || {},
    component: () => import("/app/pages/applications/[id].vue").then(m => m.default || m)
  },
  {
    name: "applications-id___en",
    path: "/en/applications/:id()",
    meta: _91id_937E5e9RRlObMeta || {},
    component: () => import("/app/pages/applications/[id].vue").then(m => m.default || m)
  },
  {
    name: "imprint___de",
    path: "/imprint",
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___de",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/en/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "works-id___de",
    path: "/works/:id()",
    component: () => import("/app/pages/works/[id].vue").then(m => m.default || m)
  },
  {
    name: "works-id___en",
    path: "/en/works/:id()",
    component: () => import("/app/pages/works/[id].vue").then(m => m.default || m)
  },
  {
    name: "works___de",
    path: "/works",
    meta: index9ZE7rZBSGbMeta || {},
    component: () => import("/app/pages/works/index.vue").then(m => m.default || m)
  },
  {
    name: "works___en",
    path: "/en/works",
    meta: index9ZE7rZBSGbMeta || {},
    component: () => import("/app/pages/works/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stuby5E5qKHNZHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stuby5E5qKHNZH
  },
  {
    name: component_45stuby5E5qKHNZHMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stuby5E5qKHNZH
  },
  {
    name: component_45stuby5E5qKHNZHMeta?.name,
    path: "/de-AT-sitemap.xml",
    component: component_45stuby5E5qKHNZH
  },
  {
    name: component_45stuby5E5qKHNZHMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stuby5E5qKHNZH
  }
]