import { defineStore } from 'pinia';

export const useNavigationStore = defineStore({
    id: 'navigation',
    state: () => ({
        isOpen: ref(false),
    }),
    actions: {
        toggleNavigation() {
            this.isOpen = !this.isOpen;

            const { lenis } = useLenis() as Record<string, any>;
            if (this.isOpen === true) {
                lenis.value?.stop();
            } else {
                lenis.value?.start();
            }
        },
        closeNavigation() {
            this.isOpen = false;

            const { lenis } = useLenis() as Record<string, any>;
            lenis.value?.start();
        },
    },
});
